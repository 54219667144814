import React, { Component } from 'react';
//BrowserRouter
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Login from './screen/Login';
import Reader from './screen/Reader'; 
import List from './screen/List'; 



class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
        <Route path="/read/:listId/:id" component={Reader} />
          <Route path="/read/:id" component={Reader} />
          <Route path="/list/:id" component={List} />
          <Route path="/" component={List} />
        </Switch>
      </Router>  
    );
  }
}

export default App;
