import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import TalkItem from '../component/TalkItem';
import { Icon, AnchorButton } from "@blueprintjs/core";
//import {franc} from "franc";

@withRouter
@translate()
@inject("store")
@observer
export default class TalkList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "talks": [],
            "show_talks": [],
            "end": false,
            "backUrl": "",
            "loader": false,
            "options": { "rate": 1.0 },
        };
        this.end_ref = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.alltalks = this.props.talks;
        let listId = this.props.match.params.listId;
        let backUrl = "/"
        if (listId) backUrl = "/list/" + listId
        this.setState({ "talks": this.props.talks, "end": this.props.talks.length < 1, "backUrl": backUrl });
    }

    componentDidUpdate(prevProps) {
        if (this.props.current_talk_id !== prevProps.current_talk_id) {
            this.loadData();
            this.showToId(this.props.current_talk_id);
            this.toBottom();
            // console.log( "now id" , this.props.current_talk_id );
        }
    }

    showToId = (id) => {
        let to_index = -1;
        this.alltalks.forEach((item, index) => {
            if (item.id == id) to_index = index;
            // else console.log( "item.id != id", item.id , id );

        });

        console.log("to_index", to_index);
        if (to_index >= 0) {
            this.setState({
                "show_talks": this.alltalks.slice(0, to_index + 1),
                "talks": this.alltalks.slice(to_index + 1)
            })

            setTimeout(() => {
                this.toBottom();
            }, 100);
        }
    }



    showOne = () => {
        this.clearLastNode();
        const state = this.state;
        if (state.talks.length < 1) {
            this.setState({ "end": true });
            return false;
        }

        let talks1 = state.talks;
        let thetalk = talks1.shift();
        let show_talks1 = state.show_talks;
        show_talks1.push(thetalk);

        this.setState({ "talks": talks1, "show_talks": show_talks1 });
        if (state.loader) {
            this.textToSpeech(thetalk.text, state.options);
        }
        setTimeout(() => {
            this.toBottom();
        }, 100);


        return thetalk;

    }

    toBottom = () => {
        if (this.end_ref && this.end_ref.current)
            this.end_ref.current.scrollIntoView(true);
    }

    goBack() {
        if (!window.confirm("当前章节已经完成，是否返回首页？")) return false;
        const state = this.state;
        this.props.history.push(state.backUrl);
    }
    regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });
    langEq = (lang1, lang2) => {
        
        let langStr1 = this.regionNamesInEnglish.of(lang1.replace("_", "-"))
        let langStr2 = this.regionNamesInEnglish.of(lang2)
        
        let flag = false;
        if (langStr1.indexOf(langStr2) !== -1) {
            //console.log("1. ",langStr1.indexOf(langStr2) !== -1);
            flag = true;
        } else if (langStr2.indexOf(langStr1) !== -1) {
            //console.log("2. ",langStr1.indexOf(langStr2) !== -1);
            flag = true;
        } else {
            flag = langStr1 === langStr2
        }
        return flag;
    }

    textToSpeech = async (text, options = {}) => {
        const state = this.state;
        const synth = window.speechSynthesis;
        synth.cancel();
        // Check if Web Speech API is available
        if (!('speechSynthesis' in window)) {
            this.state.loader = false
            playButton.value = this.state.loader ? "■" : "▶";
            alert("The current browser does not support text-to-speech");
            return;
        }

        // Detect language using franc library
        const { franc } = await import("franc")//await import("https://cdn.jsdelivr.net/npm/franc@6.1.0/+esm");
        let lang = franc(text);
        if (lang === "" || lang === "und") {
            lang = navigator.language
        }
        if (lang === "cmn") {
            lang = "zh-CN"
        }

        // Get available voices and find the one that matches the detected language
        const voices = await new Promise(resolve => {
            const voices = synth.getVoices();
            resolve(voices);
        });
 
        let voice = voices.find((v) => {
            if(this.langEq(v.lang, lang) && !v.localService){
                //alert(this.langEq(v.lang, lang)+":"+!v.localService +":"+v.name+":"+v.default)
            };
            return this.langEq(v.lang, lang) && !v.localService
        })

        if (!voice) {
            voice = voices.find((v1) => {
                if(this.langEq(v1.lang, lang) && v1.localService){
                    //alert("local:"+this.langEq(v1.lang, lang)+":"+v1.localService +":"+v1.name+":"+v1.default)
                };
                return this.langEq(v1.lang, lang) && v1.localService
            })
        }
        if(!voice){
            this.state.loader = false
            playButton.value = this.state.loader ? "■" : "▶";
            alert("The current browser does not have any tts voice");
            return;
        }
        // Create a new SpeechSynthesisUtterance object and set its parameters
        let utterance = new SpeechSynthesisUtterance(text);

        utterance.voice = voice;
        utterance.rate = options.rate || 1.0;
        utterance.pitch = options.pitch || 1.0;
        utterance.volume = options.volume || 1.0;

       
        utterance.addEventListener('boundary', (event) => {
            const { charIndex, elapsedTime } = event;
            //const progress = charIndex / utterance.text.length
            // console.log(`当前朗读进度：${progress * 100}%, 时间：${elapsedTime}`);
            let item = state.show_talks[state.show_talks.length - 1]
            let itemText = item.text;
            let lastNode = document.querySelector("div[data-id='" + item.id + "'] div.contentside div.text p");
            let index = itemText.length - text.length + charIndex;
            let background=item.role_ismain?"red":"yellow";
            lastNode.innerHTML = itemText.substring(0, index) + "<span style='background: "+background+";'>" + itemText.substr(index, 1) + "</span>" + itemText.substr(index + 1)
            //console.log(event.target.rate);
            if (speedReset.value != state.options.rate) {
                this.cancelSpeech()
                state.options.rate = speedReset.value;
                this.textToSpeech(text.substr(charIndex), state.options)
            }

            //console.log(event.target.rate );
            //const playButton = document.getElementById('playButton');
            /*if(!state.loader){
                synth.cancel()
            }*/
            
        });
        utterance.addEventListener('end', (event) => {
            this.clearLastNode();
            if (state.loader) {
                this.showOne();
            } else {
                synth.cancel()
            }
        });
         // Speak the text
        synth.speak(utterance);

        //ios端手动触发
        if(!synth.paused && !synth.pending && !synth.speaking){
            setTimeout(() => {
                if (!speechSynthesis.speaking) {
                    this.auto();
                    alert("初始化完毕，请点击播放按钮")
                }
            },300);
        }
        //alert(synth.paused+":"+synth.pending+":"+synth.speaking);
        
    }
    clearLastNode() {
        const state = this.state;
        if (state.show_talks.length == 0) return;
        const item = state.show_talks[state.show_talks.length - 1]
        const lastNode = document.querySelector("div[data-id='" + item.id + "'] div.contentside div.text p");
        if (lastNode)
            lastNode.innerHTML = lastNode.innerText;
    };
    cancelSpeech() {
        const synth = window.speechSynthesis;
        synth.cancel();
        this.clearLastNode();
    }
    auto() {
        const state = this.state;
        // console.log(this.state.loader ? "■" : "▶", this.state, state);
        this.state.loader = !state.loader
        const playButton = document.getElementById('playButton');
        playButton.value = this.state.loader ? "■" : "▶";
        //console.log(this.state.loader ? "■" : "▶", this.state, state);
        if (this.state.loader) {
            let show_talks_len = state.show_talks.length;
            if (show_talks_len > 0) {
                this.textToSpeech(state.show_talks[show_talks_len - 1].text, state.options);
            } else {
                this.showOne();
            }
        } else {
            this.cancelSpeech();
        }
    }
    speed(e) {
        //console.log(this)
        const r = 0.25
        const speedReset = document.getElementById('speedReset');
        let cur_rate = parseFloat(speedReset.value);
        switch (e.target.value) {
            case ">":
                speedReset.value = cur_rate > 8 ? 8 : cur_rate + r
                break;
            case "<":
                speedReset.value = cur_rate <= 0.25 ? 0.25 : cur_rate - r
                break;
            default:
                speedReset.value = 1.0

        }


    }
    populateVoiceList() {
        if(typeof speechSynthesis === 'undefined') {
          return;
        }
        var voices = speechSynthesis.getVoices();
        for(var i = 0; i < voices.length; i++) {
          var option = document.createElement('option');
          option.textContent = voices[i].name + ' (' + voices[i].lang + ')';
          if(voices[i].default) {
            option.textContent += ' -- DEFAULT';
          }
          option.setAttribute('data-lang', voices[i].lang);
          option.setAttribute('data-name', voices[i].name);
          document.getElementById("voiceSelect").appendChild(option);
        }
      }
     
    render() {
        const state = this.state;
        const props = this.props;

        return state.talks ?
            <div className="talklist-ro" >
                <div className="thelist">
                    {state.show_talks.length > 0
                        ?
                        <>
                            {state.show_talks.map(item => <TalkItem roles={props.roles} key={item.id} data={item} />)}
                        </>
                        :

                        <div className="guide">
                            <div className="logo"> <img src="/image/h2.logo.png" /> </div>
                            <div className="back">
                                <Link to={state.backUrl}><Icon iconSize={40} icon="circle-arrow-left" /></Link>
                            </div>
                            {this.props.meta && <><div className="name">{this.props.meta.name}</div>
                                <div className="author"><Icon icon="user" /> {this.props.meta.author}</div>
                                <div className="link"><Icon icon="link" /> {this.props.meta.link}</div>
                                <div className="detail">{this.props.meta.detail}</div></>}
                            <div className="continue">点击下方的 ✦ 开始，点击▶自动播放</div>


                        </div>
                    }

                    <div ref={this.end_ref} style={{ "marginTop": "20px" }}></div>

                </div>

                <div id="auto-play" className='auto-play'>
                    <input id="playButton" onClick={(e) => this.auto(e)} type="submit" value={state.loader ? "■" : "▶"} />
                    <input id="speedUp" onClick={(e) => this.speed(e)} type="submit" value="<" />
                    <input id="speedReset" onClick={(e) => this.speed(e)} type="submit" value={state.options.rate} />
                    <input id="speedDown" onClick={(e) => this.speed(e)} type="submit" value=">" />
                </div>

                <div className="touchpad" onClick={() => this.showOne()}>
                    {state.end ? <span className="blink noselect" onClick={() => this.goBack()}> done </span> : <span className="blink noselect" > ✦ </span>}
                </div>

            </div> : null;
    }
}